






































































































































import { Component, Prop } from 'vue-property-decorator';
import Multiselect from 'vue-multiselect';
import { mixins } from 'vue-class-component';
import FileUpload from '@/components/tasqsCommon/FileUpload.vue';
import { getComponent } from '@/utils/helpers';
import accountModule from '@/store/modules/accountModule';
import { getNameByEmail } from '@/utils/users';
import tasqActionsModule from '../../store/modules/tasqActionsModule';

@Component({
  components: {
    FileUpload,
    Checklist: () => getComponent('tasqsCommon/Checklist'),
    Multiselect,
  },
})
export default class WorkTicketSidebar extends mixins() {
  @Prop({ type: Object, required: true }) activeWorkTicket?: any;

    activeTab = 'Details'

    workTicketFiles: any = []

    workTicket: any = {}

    created() {
      // console.log(this.activeWorkTicket);
      this.workTicket = { ...this.activeWorkTicket };

      this.prepareSidebarData();
    }

    prepareSidebarData() {
      this.workTicketSafetySteps = this.workTicket.details;

      this.workTicketFiles = this.workTicket.files;

      //   set createdBy

      this.ticketDetailsSection = this.ticketDetailsSection.map((step) => {
        const stepDetails = step;
        if (stepDetails.title === 'CreatedBy') {
          stepDetails.value = getNameByEmail(this.workTicket.username);
        } else if (stepDetails.title === 'Assignee') {
        //   stepDetails.value = this.workTicket.username;
        } else if (stepDetails.title === 'Priority') {
          stepDetails.value = this.workTicket.priority;
        }
        return stepDetails;
      });
    }

    get availableUsers() {
      return accountModule.reassignmentList.map((i) => ({
        // @ts-ignore
        text: i.name,
        value: i.email,
      }));
    }

    checklist: any = []

    workTicketSafetySteps = [

      {
        title: 'Is there a safety concern?',
        checked: true,
        hasDetailSection: true,
        name: 'safetyConcernRadioGroup',
        placeholder: 'Safety concern details here ...',
        options: [{ value: true, label: 'Yes' }, { value: false, label: 'No' }],
        details: 'Details for safety Concern go here',
      },

      {
        title: 'Is there an environmental concern?',
        checked: true,
        hasDetailSection: true,
        name: 'environmentalConcernRadioGroup',
        placeholder: 'Environmental concern details here ...',
        options: [{ value: true, label: 'Yes' }, { value: false, label: 'No' }],
        details: 'Environmental concern details here ...',
      },

      {
        title: 'Is this work ticket tied to deferment?',
        checked: false,
        hasDetailSection: false,
        hasDefermentSection: true,
        name: 'tiedToDefermentRadioGroup',
        placeholder: 'Environmental concern details here ...',
        options: [{ value: true, label: 'Yes' }, { value: false, label: 'No' }],
        details: '',
      },

    ]

    ticketDetailsSection = [
      {
        title: 'CreatedBy',
        type: 'text',
        value: 'Umar Ashraf',
      },
      {
        title: 'Assignee',
        placeholder: 'Select Assignee',
        options: ['Test'],
        type: 'dropdown',
        value: null,
      },
      {
        title: 'Priority',
        placeholder: 'Select Priority',
        type: 'dropdown',
        options: ['Highest', 'High', 'Medium', 'Low', 'Lowest'],
        value: null,
      },
      {
        title: 'Start Date',
        placeholder: 'Select Date',
        type: 'datepicker',
        options: ['Test'],
        value: null,
      },
      {
        title: 'Due Date',
        placeholder: 'Select Date',
        type: 'datepicker',
        options: ['Test'],
        value: null,
      },
    ]

    get safetyConcernsDetails() {
      const safetyConcern = this.workTicketSafetySteps.find((step) => step.name === 'safetyConcernRadioGroup');
      return {
        title: 'Safety Concern', checkedValue: safetyConcern?.checked ? 'Yes' : 'No', details: safetyConcern?.details, checked: safetyConcern?.checked,
      };
    }

    get environmentalConcernsDetails() {
      const environmentalConcern = this.workTicketSafetySteps.find((step) => step.name === 'environmentalConcernRadioGroup');
      return {
        title: 'Environmental Concern', checkedValue: environmentalConcern?.checked ? 'Yes' : 'No', details: environmentalConcern?.details, checked: environmentalConcern?.checked,
      };
    }

    get defermentDetails() {
      const safetyConcern = this.workTicketSafetySteps.find((step) => step.name === 'tiedToDefermentRadioGroup');
      return {
        title: 'Causing Deferment', checkedValue: safetyConcern?.checked ? 'Yes' : 'No', details: '', checked: false,
      };
    }

    get safetySectionDetails() {
      return [this.safetyConcernsDetails, this.environmentalConcernsDetails, this.defermentDetails];
    }

    updateChecklist(list) {
      this.checklist = list;
    }

    async saveWorkTicket() {
      console.log(this.workTicket);
      console.log({
        assetType: this.workTicket.level,
        well: this.workTicket.wellName,
        jobType: this.workTicket.jobType,
        assignedToUser: accountModule.user.email,
        jobCateogry: this.workTicket.jobCategory,
        requestDetails: this.workTicket.requestDetails,
        requestCompletionDate: this.workTicket.requestCompletionDate,
        files: this.workTicketFiles,
        checklist: this.checklist,
        priority: this.ticketDetailsSection.find((step) => step.title === 'Priority')?.value,
        details: this.workTicket.details,
      });

      
      await tasqActionsModule.updateWorkTicket({
        assetType: this.workTicket.level,
        well: this.workTicket.wellName,
        jobType: this.workTicket.jobType,
        assignedToUser: accountModule.user.email,
        jobCateogry: this.workTicket.jobCategory,
        requestDetails: this.workTicket.requestDetails,
        requestCompletionDate: this.workTicket.requestCompletionDate,
        files: this.workTicketFiles,
        checklist: this.checklist,
        priority: this.ticketDetailsSection.find((step) => step.title === 'Priority')?.value,
        details: this.workTicket.details,
      });
    }
}

